.page .bb-DisclosureContent {
  background: none;
  border: none;
  padding-top: 39px;
  min-height: calc(54px + 39px);
  height: calc(54px + 39px);
  box-sizing: border-box;
  left: 0;
  right: 0;
  max-width: 1600px;
  margin: 0 auto;
}
.page .bb-DisclosureContent .bb-TopNav {
  padding: 0 72px;
  align-items: center;
  min-height: 100%;
}
.page .bb-DisclosureContent .bb-TopNav .logo {
  font-size: 45px;
}
.page .bb-DisclosureContent .bb-TopNav .logo a {
  color: #fff;
}
.page .bb-DisclosureContent .bb-TopNav .logo a:hover {
  text-decoration: none;
  opacity: 0.9;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu {
  align-items: center;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu a {
  min-height: inherit;
  height: inherit;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  padding: 0 !important;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu a.active {
  font-weight: 800 !important;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu a:not(:last-child) {
  margin-right: 48px !important;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu a:focus {
  text-decoration: none;
  box-shadow: none;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu img {
  width: 46px;
  height: 46px;
  background: rgba(255,233,234,0.8);
  border: 1px solid rgba(255,255,255,0.75);
  box-sizing: border-box;
  padding: 0 !important;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .bb-TopNavSection {
  margin: 0;
  position: relative;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover {
  margin-left: -105px;
  max-width: 151px;
  min-width: 151px;
  padding: 0;
  border-radius: 8px;
  box-shadow: none !important;
  overflow: hidden;
  margin-top: 7px;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover hr {
  display: none;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover a,
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover div {
  margin-right: 0 !important;
  padding: 12px 19px !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #732938 !important;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover a:hover,
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover div:hover {
  background-color: #fcf0ea;
}
.page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover div {
  background: url("/images/logout.svg") no-repeat;
  background-position: calc(100% - 14px) center;
}
@media (min-width: 992px) and (max-width: 1600px) {
  .page .bb-DisclosureContent {
    padding-top: 2.4375vw;
    max-width: 100vw;
    height: 5.8125vw;
    min-height: 5.8125vw;
  }
  .page .bb-DisclosureContent .bb-TopNav {
    padding: 0 4.5vw;
  }
  .page .bb-DisclosureContent .bb-TopNav .logo {
    font-size: 2.8125vw;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu a {
    font-size: 1.25vw;
    line-height: 1.5vw;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu a:not(:last-child) {
    margin-right: 3vw !important;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu img {
    width: 2.875vw;
    height: 2.875vw;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover {
    margin-left: -6.5625vw;
    max-width: 9.4375vw;
    min-width: 9.4375vw;
    border-radius: 0.5vw;
    margin-top: 4.375vw;
    transform: none !important;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover a,
  .page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover div {
    font-size: 1vw;
    line-height: 1.25vw;
  }
  .page .bb-DisclosureContent .bb-TopNav .top-menu .header-dropdown .bb-DropdownMenuPopover div {
    background-size: 0.875vw;
  }
}
@media (max-width: 991px) {
  .page .bb-PageWithHeaderHeader {
    padding-top: 24px;
    min-height: 50px;
    height: 50px;
  }
  .page .bb-PageWithHeaderHeader .bb-TopNav {
    padding: 0 15px;
  }
  .page .bb-PageWithHeaderHeader .bb-TopNav .logo {
    font-size: 26px;
  }
  .page .bb-PageWithHeaderHeader .bb-TopNav svg {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .bb-PageWithSidebar > .bb-DisclosureContent {
    display: none;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper {
    display: inherit;
    width: 100%;
    box-shadow: none;
    margin-top: -1px;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar {
    width: 100%;
    border-right: none;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu {
    background: linear-gradient(100deg, #de718b 0%, #f3b3a8 44%, #ffdab2 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu svg {
    position: absolute;
    top: 31px;
    right: 15px;
    fill: #fff;
    width: 26px;
    height: 26px;
    cursor: pointer;
    color: #fff;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul a {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    background: none;
    box-shadow: none;
    text-transform: none !important;
    color: #fff;
    min-height: inherit;
    margin-bottom: 24px;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul a.log-out {
    display: inline-block;
    width: auto;
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8.74991 7.58353C8.42734 7.58353 8.16662 7.8449 8.16662 8.16682V10.5002C8.16662 10.8216 7.90525 11.0835 7.58322 11.0835H5.83324V2.33358C5.83324 1.83542 5.5159 1.39034 5.03878 1.22467L4.86607 1.16689H7.58322C7.90525 1.16689 8.16662 1.42879 8.16662 1.75029V3.50027C8.16662 3.8222 8.42734 4.08356 8.74991 4.08356C9.07248 4.08356 9.3332 3.8222 9.3332 3.50027V1.75029C9.3332 0.785469 8.54804 0.000305176 7.58322 0.000305176H1.31249C1.29027 0.000305176 1.27168 0.0102386 1.25011 0.0131224C1.22202 0.0107726 1.19521 0.000305176 1.16669 0.000305176C0.523264 0.000305176 0 0.523463 0 1.16689V11.6668C0 12.165 0.317334 12.61 0.794456 12.7757L4.30499 13.9459C4.42398 13.9827 4.54233 14.0002 4.66665 14.0002C5.31008 14.0002 5.83324 13.4769 5.83324 12.8335V12.2502H7.58322C8.54804 12.2502 9.3332 11.465 9.3332 10.5002V8.16682C9.3332 7.8449 9.07248 7.58353 8.74991 7.58353Z' fill='white'/%3E%3Cpath d='M13.8295 5.42103L11.4961 3.08776C11.3294 2.92092 11.0785 2.87072 10.8604 2.96108C10.6428 3.05155 10.5004 3.26442 10.5004 3.50016V5.25014H8.16714C7.84511 5.25014 7.58374 5.51139 7.58374 5.83343C7.58374 6.15546 7.84511 6.41672 8.16714 6.41672H10.5004V8.1667C10.5004 8.40243 10.6428 8.61531 10.8604 8.70577C11.0785 8.79614 11.3294 8.74593 11.4961 8.5792L13.8295 6.24582C14.0575 6.01778 14.0575 5.64907 13.8295 5.42103Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='14' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 24px;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul.mobile-menu-avatar {
    margin-bottom: 42px;
    display: block;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul.mobile-menu-avatar a {
    margin-bottom: 0;
  }
  body .bb-PageWithSidebarSidebarCollapsedWrapper .bb-PageWithSidebarSidebar .mobile-menu ul.mobile-menu-avatar a img {
    width: 58px;
    height: 58px;
    border: 1px solid #fff;
    font-size: inherit;
  }
}
