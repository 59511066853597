.footer {
  background: #e28d9d;
  position: fixed;
  z-index: 2;
  width: 100%;
  bottom: 0;
  left: 0;
}
.footer.rel {
  position: relative;
}
.footer .bb-Columns {
  padding: 27px 70px;
  margin: 0 auto !important;
  width: 1600px;
  box-sizing: border-box;
}
.footer .bb-Columns > div {
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.footer .bb-Columns > div .bb-Set > * {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.footer .bb-Columns > div .bb-Set .bb-Link {
  color: #fff;
}
.page-profile:not(.profile-connections) .footer,
.page-mooddiary .footer,
.page-exercises .footer,
.page-draw .footer,
.game-GAMEID6 .footer,
.game-GAMEID7 .footer,
.page-communicator .footer,
.page-privacypolicy .footer,
.page-terms .footer,
.page-help .footer {
  position: relative;
}
@media (min-width: 992px) and (max-width: 1600px) {
  .footer .bb-Columns {
    padding: 1.6875vw 4.375vw;
    width: 100vw;
  }
  .footer .bb-Columns .bb-ColumnsColumn:first-child {
    width: 50%;
  }
  .footer .bb-Columns > div {
    font-size: 1vw;
    line-height: 1.25vw;
  }
  .footer .bb-Columns > div .bb-Set > * {
    font-size: 1vw;
    line-height: 1.25vw;
  }
}
@media (max-width: 991px) {
  .footer {
    margin-top: 64px !important;
    position: relative !important;
  }
  .footer .bb-Columns {
    width: 100%;
    padding: 30px;
    flex-direction: column;
  }
  .footer .bb-Columns > div .bb-Set > * {
    font-size: 12px;
    line-height: 15px;
    margin-left: 0;
    margin-top: 0;
  }
  .footer .bb-Columns .bb-ColumnsColumn {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
  }
  .footer .bb-Columns .bb-ColumnsColumn:nth-child(1) {
    order: 2;
    margin-top: 28px;
  }
  .footer .bb-Columns .bb-ColumnsColumn:nth-child(2) {
    order: 1;
  }
  .footer .bb-Columns .bb-ColumnsColumn .bb-Set {
    margin-left: 0;
    margin-top: 0;
  }
  .footer .bb-Columns .bb-ColumnsColumn .bb-Set .bb-Box {
    width: 100%;
    margin-bottom: 14px;
  }
  .footer .bb-Columns .bb-ColumnsColumn .bb-Set .bb-Box:last-child {
    margin-bottom: 0;
  }
  .page-home-login .footer {
    position: relative;
  }
}
