.preloader {
  background: #de718b;
  background: linear-gradient(100deg, #de718b 0%, #f3b3a8 44%, #ffdab2 100%);
  backdrop-filter: blur(132px);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}
.preloader h2 {
  font-family: 'Fredoka One';
  margin: 0;
  font-weight: normal;
  font-size: 140px;
  line-height: 169px;
  letter-spacing: 0.04em;
}
.preloader h2 span {
  display: inline-block;
  color: #fff;
  animation-name: wiggleWiggle;
  animation-duration: 1.25s;
  animation-direction: alternate;
  animation-timing-function: steps(2, end);
  animation-iteration-count: infinite;
}
.preloader h2 span:nth-child(1) {
  animation-delay: 0.1s;
}
.preloader h2 span:nth-child(2) {
  animation-delay: 0.2s;
}
.preloader h2 span:nth-child(3) {
  animation-delay: 0.3s;
}
.preloader h2 span:nth-child(4) {
  animation-delay: 0.4s;
}
.preloader h2 span:nth-child(5) {
  animation-delay: 0.5s;
}
.preloader p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 68px;
  line-height: 83px;
  margin: 0 0 32px;
}
@media (min-width: 992px) and (max-width: 1600px) {
  .preloader h2 {
    font-size: 8.75vw;
    line-height: 10.5625vw;
  }
  .preloader p {
    font-size: 4.25vw;
    line-height: 5.1875vw;
    margin: 0 0 2vw;
  }
}
@media (max-width: 991px) {
  .preloader h2 {
    font-size: 58px;
    line-height: 70px;
  }
  .preloader p {
    font-size: 27px;
    line-height: 33px;
    margin: 0 0 7px;
  }
}
