.not-found .page .bb-DisclosureContent {
  display: none;
}
.not-found .page .bb-PageWithHeaderContent {
  padding: 0;
}
.not-found .page .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  min-height: 100vh;
}
.not-found .footer {
  display: none;
}
.page {
  background: #de718b;
  background: linear-gradient(100deg, #de718b 0%, #f3b3a8 44%, #ffdab2 100%);
}
.page.page-home-login {
  background: #fccaa5;
  background: linear-gradient(100deg, #fccaa5 0%, #f66b66 44%, #f32c36 100%);
}
.page.page-copycat {
  background: #fe8833;
  background: linear-gradient(56deg, #fe8833 0%, #db4245 50%, #b51349 100%);
}
.page.page-quiz {
  background: #fd8f83;
  background: linear-gradient(56deg, #fd8f83 0%, #f48381 50%, #b12d6e 100%);
  overflow: hidden;
}
.page.page-mooddiary {
  background: #feb6a0;
  background: radial-gradient(circle, #feb6a0 36%, #d43934 100%);
}
.page.page-mooddiary .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  min-height: calc(100vh - 231px);
  max-height: inherit;
}
.page.page-log {
  background: #d57543;
  background: linear-gradient(115deg, #d57543 0%, #d5503f 100%);
}
.page.page-log .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  align-items: flex-start;
}
.page.page-exercises {
  background: #d9628a;
  background: linear-gradient(115deg, #d9628a 0%, #e08777 100%);
}
.page.page-exercises .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  align-items: flex-start;
}
.page.page-draw {
  background: #df4706;
  background: linear-gradient(69deg, #df4706 0%, #e17c4e 42%, #efa87c 51%, #efa87c 100%);
}
.page.page-draw .bb-PageWithHeaderContent {
  padding-top: 62px;
}
.page.page-draw .bb-PageWithHeaderContent .bb-PageContentWrapper {
  align-items: flex-start;
}
.page.page-exercise {
  background: #df4706;
  background: linear-gradient(69deg, #df4706 0%, #e17c4e 42%, #efa87c 51%, #efa87c 100%);
}
.page.page-exercise .bb-PageWithHeaderContent .bb-PageContentWrapper {
  align-items: flex-start;
}
.page.page-profile {
  background: #fbb176;
  background: linear-gradient(317deg, #fbb176 0%, #a43056 31%, #e82b79 69%, #efa87c 100%);
}
.page.page-profile .bb-PageWithHeaderContent {
  padding-top: 68px;
}
.page.page-profile .bb-PageWithHeaderContent > .bb-Box {
  min-height: inherit !important;
}
.page.page-profile .bb-PageWithHeaderContent .bb-PageContentWrapper {
  align-items: flex-start;
  min-height: inherit;
}
.page.home-carer {
  background: #fbb176;
  background: linear-gradient(317deg, #fbb176 0%, #a43056 31%, #e82b79 69%, #efa87c 100%);
}
.page.home-carer .bb-PageWithHeaderContent {
  padding-top: 68px;
}
.page.home-carer .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  min-height: calc(100vh - 235px);
  align-items: flex-start;
}
.page.home-carer .bb-PageWithHeaderContent > .bb-PageContentWrapper .bb-Container {
  max-width: 1460px;
}
.page .bb-PageWithHeaderContent {
  padding-top: 51px;
}
.page .bb-PageWithHeaderContent > .bb-PageContentWrapper {
  min-height: calc(100vh - 218px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-bottom: 72px;
}
.page .bb-PageContent {
  padding: 0;
}
.page .bb-PageContent.text-page {
  max-width: 860px;
}
.page .bb-PageContent.text-page h1 {
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 51px;
  letter-spacing: 0.04em;
  color: #fff;
  margin: 0 0 36px;
}
.page .bb-PageContent.text-page .text-page-wrapper {
  background: #fff;
  border-radius: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #4b4b4b;
  padding: 48px 0 48px 53px;
  margin-bottom: 72px;
}
.page .bb-PageContent.text-page .text-page-wrapper a {
  color: #e7898f;
}
.page .bb-PageContent.text-page .text-page-wrapper .text-page-wrapper-inner {
  max-height: 819px;
  overflow-y: scroll;
  max-width: calc(100% - 28px);
  padding-right: 39px;
}
.page .bb-PageContent.text-page .text-page-wrapper .text-page-wrapper-inner::-webkit-scrollbar {
  width: 3px;
}
.page .bb-PageContent.text-page .text-page-wrapper .text-page-wrapper-inner::-webkit-scrollbar-track {
  box-shadow: none;
  background: #e5e5e5;
  border-radius: 64px;
}
.page .bb-PageContent.text-page .text-page-wrapper .text-page-wrapper-inner::-webkit-scrollbar-thumb {
  background-color: #ee3c5f;
  border-radius: 64px;
}
.page .bb-PageContent.text-page .text-page-wrapper h2 {
  margin: 0 0 16px;
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: #e7898f;
}
.page .bb-PageContent.text-page .text-page-wrapper p {
  margin: 0 0 16px;
}
.page .bb-PageContent.text-page .text-page-wrapper ul {
  padding: 0;
  list-style: none;
}
.page .bb-PageContent.text-page .text-page-wrapper ul li {
  position: relative;
  margin-bottom: 16px;
  padding-left: 23px;
}
.page .bb-PageContent.text-page .text-page-wrapper ul li::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #e7898f;
  border-radius: 100%;
  left: 0;
  top: 7px;
}
.page .bb-PageContent.page-404 h1 {
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 220px !important;
  line-height: 266px !important;
  letter-spacing: 0.04em;
  color: #fff;
  margin: 0 0 32px;
}
.page .bb-PageContent.page-404 p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin: 0 0 22px;
}
.page .bb-PageContent.page-404 .goto-main {
  background: #ee3c5f;
  border-radius: 54px;
  width: 268px;
  height: 54px;
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 54px;
  letter-spacing: 0.04em;
  color: #fff;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.page .bb-PageContent.page-404 img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
}
.page .wrapper {
  max-width: 100%;
  padding: 0;
}
.page h1 {
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 60px !important;
  line-height: 73px !important;
  letter-spacing: 0.04em;
  margin: 0;
}
.page .fogrot-pasw-text {
  font-family: Fredoka One;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
}
.page .fogrot-pasw-text .fogrot-pasw-link {
  display: block;
  width: 100%;
  height: 54px;
  background: #ee3c5f;
  border-radius: 10px;
  font-family: Fredoka One;
  font-weight: normal;
  font-size: 16px;
  line-height: 54px;
  letter-spacing: 0.04em;
  color: #fff;
  margin-top: 31px;
}
.page .fogrot-pasw-text img {
  display: block;
  margin: 0 auto 16px;
}
.page .fogrot-pasw-text strong {
  font-family: Fredoka One;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ee3c5f;
  display: block;
  margin-top: 4px;
}
.page button:focus {
  box-shadow: none !important;
}
.page input:not([type="submit"]) {
  box-shadow: none !important;
}
.page .bb-FieldWrapperValidationText {
  margin-top: 5px;
}
.page select,
.page input:not([type="range"]),
.page button {
  -webkit-appearance: none;
}
.page .small-wrapper {
  max-width: 491px;
}
@media (min-width: 992px) and (max-width: 1600px) {
  html,
  body {
    font-size: 1vw !important;
  }
  .page.page-draw .bb-PageWithHeaderContent {
    padding-top: 3.875vw;
  }
  .page.page-profile .bb-PageWithHeaderContent {
    padding-top: 4.25vw;
  }
  .page.home-carer .bb-PageWithHeaderContent {
    padding-top: 4.25vw;
  }
  .page.home-carer .bb-PageWithHeaderContent > .bb-PageContentWrapper .bb-Container {
    max-width: 91.25vw;
  }
  .page.page-login .bb-PageWithHeaderContent > .bb-PageContentWrapper .bb-Container,
  .page.page-signup .bb-PageWithHeaderContent > .bb-PageContentWrapper .bb-Container {
    max-width: 30vw;
  }
  .page .bb-PageWithHeaderContent {
    padding-top: 3.1875vw;
  }
  .page .bb-PageWithHeaderContent > .bb-PageContentWrapper {
    min-height: 79.8vh;
  }
  .page h1 {
    font-size: 3.75vw !important;
    line-height: 4.5625vw !important;
  }
  .page .bb-FieldWrapperValidationText {
    margin-top: 0.3125vw;
  }
  button {
    min-height: inherit !important;
  }
}
@media (max-width: 991px) {
  .preloader h2 {
    font-size: 58px;
    line-height: 70px;
  }
  .preloader p {
    font-size: 27px;
    line-height: 33px;
  }
  .page .bb-PageContent.text-page .text-page-wrapper {
    padding: 30px 0 30px 30px;
  }
  .page .bb-PageContent.text-page .text-page-wrapper .text-page-wrapper-inner {
    padding-right: 15px;
    max-width: calc(100% - 15px);
  }
  .page .bb-PageContent.page-404 {
    padding-top: 100px;
  }
  .page .bb-PageContent.page-404 h1 {
    font-size: 100px !important;
    line-height: 1 !important;
  }
  .page .bb-PageContent.page-404 p {
    font-size: 18px;
  }
  .page .bb-PageContent.page-404 img {
    max-width: 100%;
  }
  .page .bb-DisclosureContent {
    max-width: 100%;
  }
  .page .bb-PageWithSidebarContent {
    padding-left: 0;
  }
  .page > .bb-PageWithHeaderContent {
    padding-top: 21px;
  }
  .page > .bb-PageWithHeaderContent .bb-PageWithSidebar {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    min-height: 61.8vh !important;
  }
  .page h1 {
    font-size: 32px !important;
    line-height: 39px !important;
  }
  .page.page-login .bb-PageWithSidebarContent {
    min-height: 58vh;
  }
  .page.page-mooddiary .bb-PageWithHeaderContent {
    padding-top: 65px !important;
  }
  .page.page-mooddiary .bb-PageWithHeaderContent h1 {
    font-size: 30px !important;
    line-height: 36px !important;
    margin-bottom: 36px !important;
  }
  .page.page-draw .bb-PageWithHeaderContent {
    padding-top: 25px !important;
  }
  .page.page-profile .bb-PageWithHeaderContent .bb-PageContentWrapper {
    min-height: 100%;
  }
  .page.home-carer .bb-PageWithHeaderContent > .bb-PageContentWrapper {
    min-height: inherit;
  }
  .page.home-carer .bb-PageWithHeaderContent > .bb-PageContentWrapper .bb-Container {
    max-width: calc(100% - 30px);
  }
}
