@-webkit-keyframes showNav {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes wiggleWiggle {
  20%, 100% {
    transform: translate(0, 10px);
  }
  0% {
    transform: translate(0, 0px);
  }
  10% {
    transform: translate(0, 10px);
  }
}
@-webkit-keyframes wiggleWiggle {
  20%, 100% {
    transform: translate(0, 10px);
  }
  0% {
    transform: translate(0, 0px);
  }
  10% {
    transform: translate(0, 10px);
  }
}
@-o-keyframes wiggleWiggle {
  20%, 100% {
    transform: translate(0, 10px);
  }
  0% {
    transform: translate(0, 0px);
  }
  10% {
    transform: translate(0, 10px);
  }
}
@keyframes wiggleWiggle {
  20%, 100% {
    transform: translate(0, 10px);
  }
  0% {
    transform: translate(0, 0px);
  }
  10% {
    transform: translate(0, 10px);
  }
}
@-moz-keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 30px;
  }
}
@-webkit-keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 30px;
  }
}
@-o-keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 30px;
  }
}
@keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 30px;
  }
}
@-moz-keyframes spin {
  0%, 10% {
    transform: rotate(-360deg);
  }
  10%, 100% {
    transform: rotate(50deg);
  }
}
@-webkit-keyframes spin {
  0%, 10% {
    transform: rotate(-360deg);
  }
  10%, 100% {
    transform: rotate(50deg);
  }
}
@-o-keyframes spin {
  0%, 10% {
    transform: rotate(-360deg);
  }
  10%, 100% {
    transform: rotate(50deg);
  }
}
@keyframes spin {
  0%, 10% {
    transform: rotate(-360deg);
  }
  10%, 100% {
    transform: rotate(50deg);
  }
}
@-moz-keyframes slideTop {
  from {
    top: 100%;
  }
  to {
    top: calc(100% - 147px);
  }
}
@-webkit-keyframes slideTop {
  from {
    top: 100%;
  }
  to {
    top: calc(100% - 147px);
  }
}
@-o-keyframes slideTop {
  from {
    top: 100%;
  }
  to {
    top: calc(100% - 147px);
  }
}
@keyframes slideTop {
  from {
    top: 100%;
  }
  to {
    top: calc(100% - 147px);
  }
}
@-moz-keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}
@-webkit-keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}
@-o-keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}
@-moz-keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
